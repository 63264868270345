html, body {
  margin: 0;
  background-color: #121212;
  font-family: sans-serif;
}

.App {
  margin: 50px 20px;
}

.Guess {
  margin-top: 20px;
}