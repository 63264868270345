.kGpYaq_List {
  max-width: 620px;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
  margin: 0 auto;
  padding: 0;
  list-style-type: none;
  display: flex;
  overflow-x: auto;
}

.kGpYaq_Item {
  flex: 0 0 200px;
}

.kGpYaq_Filler {
  height: 150px;
  width: 200px;
  box-sizing: border-box;
  background-color: #424242;
  border: 1px solid #424242;
  justify-content: center;
  align-items: center;
  display: flex;
}

.kGpYaq_Filler .kGpYaq_FillerText {
  text-align: center;
  color: #9e9e9e;
  flex: auto;
  font-size: 64px;
}

.kGpYaq_Thumbnail {
  min-width: 200px;
}

._1m3DXa_Container {
  margin: 10px 0;
}

._1m3DXa_Title {
  color: #fff;
  text-align: center;
  min-height: 1.5rem;
}

._1m3DXa_TitleLink {
  color: #64b5f6;
  text-align: center;
  min-height: 1.5rem;
  font-weight: bold;
  text-decoration: none;
  display: block;
}

._1m3DXa_TitleLink:hover, ._1m3DXa_TitleLink:active {
  text-decoration: underline;
}

._1m3DXa_Date {
  color: #fff;
  text-align: center;
  display: none;
}

._1m3DXa_DateVisible {
  display: block;
}

.qcRgWG_List {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.qcRgWG_List li {
  border: 1px solid #424242;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
  padding: 10px 15px;
  display: flex;
}

.qcRgWG_List li:first-child {
  margin-top: 0;
}

.qcRgWG_Empty {
  height: 26px;
}

.qcRgWG_GuessText {
  color: #fff;
  flex: 0 auto;
}

.qcRgWG_Hints {
  flex: 0 auto;
}

.flatpickr-calendar {
  opacity: 0;
  text-align: center;
  visibility: hidden;
  direction: ltr;
  width: 307.875px;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background: #3f4458;
  border: 0;
  border-radius: 5px;
  padding: 0;
  font-size: 14px;
  line-height: 24px;
  animation: none;
  display: none;
  position: absolute;
  box-shadow: 1px 0 #20222c, -1px 0 #20222c, 0 1px #20222c, 0 -1px #20222c, 0 3px 13px #00000014;
}

.flatpickr-calendar.open, .flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible;
}

.flatpickr-calendar.open {
  z-index: 99999;
  display: inline-block;
}

.flatpickr-calendar.animate.open {
  animation: fpFadeInDown .3s cubic-bezier(.23, 1, .32, 1);
}

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  box-shadow: -2px 0 #e6e6e6, 5px 0 #e6e6e6;
}

.flatpickr-calendar .hasWeeks .dayContainer, .flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}

.flatpickr-calendar.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #20222c;
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}

.flatpickr-calendar:before, .flatpickr-calendar:after {
  pointer-events: none;
  content: "";
  height: 0;
  width: 0;
  border: solid #0000;
  display: block;
  position: absolute;
  left: 22px;
}

.flatpickr-calendar.rightMost:before, .flatpickr-calendar.arrowRight:before, .flatpickr-calendar.rightMost:after, .flatpickr-calendar.arrowRight:after {
  left: auto;
  right: 22px;
}

.flatpickr-calendar.arrowCenter:before, .flatpickr-calendar.arrowCenter:after {
  left: 50%;
  right: 50%;
}

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}

.flatpickr-calendar.arrowTop:before, .flatpickr-calendar.arrowTop:after {
  bottom: 100%;
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #20222c;
}

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #3f4458;
}

.flatpickr-calendar.arrowBottom:before, .flatpickr-calendar.arrowBottom:after {
  top: 100%;
}

.flatpickr-calendar.arrowBottom:before {
  border-top-color: #20222c;
}

.flatpickr-calendar.arrowBottom:after {
  border-top-color: #3f4458;
}

.flatpickr-calendar:focus {
  outline: 0;
}

.flatpickr-wrapper {
  display: inline-block;
  position: relative;
}

.flatpickr-months {
  display: flex;
}

.flatpickr-months .flatpickr-month {
  color: #fff;
  fill: #fff;
  height: 34px;
  text-align: center;
  user-select: none;
  background: #3f4458;
  flex: 1;
  line-height: 1;
  position: relative;
  overflow: hidden;
}

.flatpickr-months .flatpickr-prev-month, .flatpickr-months .flatpickr-next-month {
  user-select: none;
  cursor: pointer;
  height: 34px;
  z-index: 3;
  color: #fff;
  fill: #fff;
  padding: 10px;
  text-decoration: none;
  position: absolute;
  top: 0;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled, .flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: none;
}

.flatpickr-months .flatpickr-prev-month i, .flatpickr-months .flatpickr-next-month i {
  position: relative;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month, .flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  left: 0;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-next-month, .flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  right: 0;
}

.flatpickr-months .flatpickr-prev-month:hover, .flatpickr-months .flatpickr-next-month:hover {
  color: #eee;
}

.flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747;
}

.flatpickr-months .flatpickr-prev-month svg, .flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px;
}

.flatpickr-months .flatpickr-prev-month svg path, .flatpickr-months .flatpickr-next-month svg path {
  fill: inherit;
  transition: fill .1s;
}

.numInputWrapper {
  height: auto;
  position: relative;
}

.numInputWrapper input, .numInputWrapper span {
  display: inline-block;
}

.numInputWrapper input {
  width: 100%;
}

.numInputWrapper input::-ms-clear {
  display: none;
}

.numInputWrapper input::-webkit-outer-spin-button, .numInputWrapper input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.numInputWrapper span {
  width: 14px;
  height: 50%;
  opacity: 0;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid #ffffff26;
  padding: 0 4px 0 2px;
  line-height: 50%;
  position: absolute;
  right: 0;
}

.numInputWrapper span:hover {
  background: #c0bba71a;
}

.numInputWrapper span:active {
  background: #c0bba733;
}

.numInputWrapper span:after {
  content: "";
  display: block;
  position: absolute;
}

.numInputWrapper span.arrowUp {
  border-bottom: 0;
  top: 0;
}

.numInputWrapper span.arrowUp:after {
  border-bottom: 4px solid #fff9;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  top: 26%;
}

.numInputWrapper span.arrowDown {
  top: 50%;
}

.numInputWrapper span.arrowDown:after {
  border-top: 4px solid #fff9;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  top: 40%;
}

.numInputWrapper span svg {
  width: inherit;
  height: auto;
}

.numInputWrapper span svg path {
  fill: #ffffff80;
}

.numInputWrapper:hover {
  background: #c0bba70d;
}

.numInputWrapper:hover span {
  opacity: 1;
}

.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  color: inherit;
  width: 75%;
  height: 34px;
  text-align: center;
  padding: 7.48px 0 0;
  font-weight: 300;
  line-height: 1;
  display: inline-block;
  position: absolute;
  left: 12.5%;
  transform: translate3d(0, 0, 0);
}

.flatpickr-current-month span.cur-month {
  color: inherit;
  margin-left: .5ch;
  padding: 0;
  font-family: inherit;
  font-weight: 700;
  display: inline-block;
}

.flatpickr-current-month span.cur-month:hover {
  background: #c0bba70d;
}

.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch�;
  display: inline-block;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: #fff;
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: #fff;
}

.flatpickr-current-month input.cur-year {
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  vertical-align: initial;
  appearance: textfield;
  background: none;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: 0 0 0 .5ch;
  display: inline-block;
}

.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}

.flatpickr-current-month input.cur-year[disabled], .flatpickr-current-month input.cur-year[disabled]:hover {
  color: #ffffff80;
  pointer-events: none;
  background: none;
  font-size: 100%;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  height: auto;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  vertical-align: initial;
  width: auto;
  background: #3f4458;
  border: none;
  border-radius: 0;
  outline: none;
  margin: -1px 0 0;
  padding: 0 0 0 .5ch;
  position: relative;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:focus, .flatpickr-current-month .flatpickr-monthDropdown-months:active {
  outline: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: #c0bba70d;
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: #3f4458;
  outline: none;
  padding: 0;
}

.flatpickr-weekdays {
  text-align: center;
  width: 100%;
  height: 28px;
  background: none;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  flex: 1;
  display: flex;
}

span.flatpickr-weekday {
  cursor: default;
  color: #fff;
  text-align: center;
  background: #3f4458;
  flex: 1;
  margin: 0;
  font-size: 90%;
  font-weight: bolder;
  line-height: 1;
  display: block;
}

.dayContainer, .flatpickr-weeks {
  padding: 1px 0 0;
}

.flatpickr-days {
  width: 307.875px;
  align-items: flex-start;
  display: flex;
  position: relative;
  overflow: hidden;
}

.flatpickr-days:focus {
  outline: 0;
}

.dayContainer {
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  box-sizing: border-box;
  opacity: 1;
  outline: 0;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0;
  display: flex;
  transform: translate3d(0, 0, 0);
}

.dayContainer + .dayContainer {
  box-shadow: -1px 0 #20222c;
}

.flatpickr-day {
  box-sizing: border-box;
  color: #fffffff2;
  cursor: pointer;
  width: 14.2857%;
  max-width: 39px;
  height: 39px;
  text-align: center;
  background: none;
  border: 1px solid #0000;
  border-radius: 150px;
  flex-basis: 14.2857%;
  justify-content: center;
  margin: 0;
  font-weight: 400;
  line-height: 39px;
  display: inline-block;
  position: relative;
}

.flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  background: #646c8c;
  border-color: #646c8c;
  outline: 0;
}

.flatpickr-day.today {
  border-color: #eee;
}

.flatpickr-day.today:hover, .flatpickr-day.today:focus {
  color: #3f4458;
  background: #eee;
  border-color: #eee;
}

.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  background: #80cbc4;
  border-color: #80cbc4;
}

.flatpickr-day.selected.startRange, .flatpickr-day.startRange.startRange, .flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
}

.flatpickr-day.selected.endRange, .flatpickr-day.startRange.endRange, .flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  box-shadow: -10px 0 #80cbc4;
}

.flatpickr-day.selected.startRange.endRange, .flatpickr-day.startRange.startRange.endRange, .flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px;
}

.flatpickr-day.inRange {
  border-radius: 0;
  box-shadow: -5px 0 #646c8c, 5px 0 #646c8c;
}

.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover, .flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay, .flatpickr-day.notAllowed, .flatpickr-day.notAllowed.prevMonthDay, .flatpickr-day.notAllowed.nextMonthDay {
  color: #ffffff4d;
  cursor: default;
  background: none;
  border-color: #0000;
}

.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: #ffffff1a;
}

.flatpickr-day.week.selected {
  border-radius: 0;
  box-shadow: -5px 0 #80cbc4, 5px 0 #80cbc4;
}

.flatpickr-day.hidden {
  visibility: hidden;
}

.rangeMode .flatpickr-day {
  margin-top: 1px;
}

.flatpickr-weekwrapper {
  float: left;
}

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  box-shadow: 1px 0 #20222c;
}

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px;
}

.flatpickr-weekwrapper span.flatpickr-day, .flatpickr-weekwrapper span.flatpickr-day:hover {
  width: 100%;
  max-width: none;
  color: #ffffff4d;
  cursor: default;
  background: none;
  border: none;
  display: block;
}

.flatpickr-innerContainer {
  box-sizing: border-box;
  display: flex;
  overflow: hidden;
}

.flatpickr-rContainer {
  box-sizing: border-box;
  padding: 0;
  display: inline-block;
}

.flatpickr-time {
  text-align: center;
  height: 0;
  max-height: 40px;
  box-sizing: border-box;
  outline: 0;
  line-height: 40px;
  display: flex;
  overflow: hidden;
}

.flatpickr-time:after {
  content: "";
  clear: both;
  display: table;
}

.flatpickr-time .numInputWrapper {
  width: 40%;
  height: 40px;
  float: left;
  flex: 1;
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #fffffff2;
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #fffffff2;
}

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}

.flatpickr-time input {
  -webkit-box-shadow: none;
  box-shadow: none;
  text-align: center;
  height: inherit;
  line-height: inherit;
  color: #fffffff2;
  box-sizing: border-box;
  appearance: textfield;
  background: none;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: 0;
  font-size: 14px;
  position: relative;
}

.flatpickr-time input.flatpickr-hour {
  font-weight: bold;
}

.flatpickr-time input.flatpickr-minute, .flatpickr-time input.flatpickr-second {
  font-weight: 400;
}

.flatpickr-time input:focus {
  border: 0;
  outline: 0;
}

.flatpickr-time .flatpickr-time-separator, .flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: inherit;
  color: #fffffff2;
  width: 2%;
  user-select: none;
  align-self: center;
  font-weight: bold;
}

.flatpickr-time .flatpickr-am-pm {
  width: 18%;
  cursor: pointer;
  text-align: center;
  outline: 0;
  font-weight: 400;
}

.flatpickr-time input:hover, .flatpickr-time .flatpickr-am-pm:hover, .flatpickr-time input:focus, .flatpickr-time .flatpickr-am-pm:focus {
  background: #6a7395;
}

.flatpickr-input[readonly] {
  cursor: pointer;
}

@keyframes fpFadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

._0Gi9mq_Container {
  justify-content: center;
  align-items: flex-start;
  gap: 25px;
  display: flex;
}

@media only screen and (max-width: 519px) {
  ._0Gi9mq_Container {
    display: block;
  }
}

._0Gi9mq_DatePicker {
  flex: none;
}

@media only screen and (max-width: 519px) {
  ._0Gi9mq_DatePicker {
    width: 310px;
    margin: 0 auto;
  }
}

._0Gi9mq_RightPanel {
  flex: 0 0 150px;
}

@media only screen and (max-width: 519px) {
  ._0Gi9mq_RightPanel {
    margin-top: 20px;
  }
}

._0Gi9mq_Preview {
  color: #fff;
  text-align: center;
  margin-bottom: 10px;
}

._0Gi9mq_Submit {
  width: 100%;
  cursor: pointer;
  color: #fff;
  background-color: #424242;
  border: 1px solid #757575;
  padding: 20px;
  font-weight: bold;
}

._0Gi9mq_Submit:hover, ._0Gi9mq_Submit:active {
  background-color: #616161;
}

html, body {
  background-color: #121212;
  margin: 0;
  font-family: sans-serif;
}

.App {
  margin: 50px 20px;
}

.Guess {
  margin-top: 20px;
}

/*# sourceMappingURL=index.ac8a6d6f.css.map */
